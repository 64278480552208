<template>
  <div class="y-calendar-container" :class="{ 'button-container': type == 'button'}">
    <input type="text" class="form-control" :class="{ 'hide-opacity': type !== 'input'}" id="y-calendar-input" />
    <span class="material-icons-outlined c-pointer icon-calendar p-relative" @click="show" v-if="type === 'icon'" :class="iconClass">calendar_today</span>
    <button type="button" :class="btnClass" v-if="type === 'button'" @click="show" v-tooltip.top="`${this.date}`">{{btnLabel}}</button>
  </div>
</template>

<script>
import 'daterangepicker/daterangepicker';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';

export default {
  name: 'y-date-picker',
  props: {
    value: {},
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY',
    },
    btnLabel: {
      type: String,
      default: 'btnLabel',
    },
    btnClass: {
      type: String,
      default: 'y-button-outline',
    },
    iconClass: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'input', // input | icon | button
    },
  },
  data: () => ({
    range: [],
    date: '',
  }),
  computed: {
    isSingleDatePicker() {
      return this.options.singleDatePicker;
    },
    startDate() {
      if (this.isSingleDatePicker) {
        return this.range;
      }
      return this.range[0];
    },
    endDate() {
      if (this.isSingleDatePicker) {
        return this.range;
      }
      return this.range[1];
    },
    customOptions() {
      return {
        opens: 'center',
        locale: {
          format: this.format,
          separator: ' - ',
          applyLabel: 'Ok',
          cancelLabel: 'Cancelar',
          fromLabel: 'De',
          toLabel: 'Até',
          customRangeLabel: 'Custom',
          weekLabel: 'S',
          daysOfWeek: [
            'D',
            'S',
            'T',
            'Q',
            'Q',
            'S',
            'S',
          ],
          monthNames: [
            'Janeiro',
            'Favereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ],
          firstDay: 1,
        },
        ...this.options,
      };
    },
  },
  watch: {
    value(value) {
      const el = $('#y-calendar-input');
      if (this.isSingleDatePicker) {
        el.data('daterangepicker').setStartDate(value);
      } else {
        el.data('daterangepicker').setStartDate(value[0]);
        el.data('daterangepicker').setEndDate(value[1]);
      }
    },
    range(value) {
      this.$emit('change', value);
      this.$emit('input', value);
    },
  },
  methods: {
    show() {
      $('#y-calendar-input').data('daterangepicker').show();
    },
    hide() {
      $('#y-calendar-input').data('daterangepicker').hide();
    },
  },
  created() {
    this.range = this.value;
  },
  mounted() {
    this.$nextTick(() => {
      const el = $('#y-calendar-input');
      el.daterangepicker(this.customOptions);
      el.on('apply.daterangepicker', (event, picker) => {
        const startDate = picker.startDate.format(this.format);
        const endDate = picker.endDate.format(this.format);
        if (this.isSingleDatePicker) {
          this.range = startDate;
          this.date = startDate;
        } else {
          this.range = [startDate, endDate];
          this.date = `${startDate} - ${endDate}`;
        }
      });
      el.on('cancel.daterangepicker', () => {
        if (this.isSingleDatePicker) {
          this.range = '';
        } else {
          this.range = [];
        }
      });
    });
  },
  beforeDestroy() {
    $('#y-calendar-input')
      .daterangepicker('hide')
      .daterangepicker('destroy');
  },
};
</script>

<style scoped>
.y-calendar-container {
  position: relative;
}
.y-calendar-container input {
  position: absolute;
}
.hide-opacity {
  opacity: 0;
}
.icon-calendar {
  font-size: 30px;
}
.y-button-outline {
  padding: 6px 24px;
  border-radius: 8px;
  background: #6B23DC;
  color: #fff;
  border: none;
  margin-top: 15px;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  margin-left: 22px;
}

.y-button-outline {
  background: #fff;
  color: #6B23DC;
  border: 1px solid #6B23DC;
}

.y-button-outline:hover {
  background: #732be6;
}

.y-button-outline:hover {
  background: #e4d8f7;
}
button {
  z-index: 1;
  position: relative;
}
.button-container input{
  left: 30px;
}
</style>
